import { useState, useEffect, useCallback, RefObject } from 'react';

export type UseGridCardSizeProps = {
    containerRef: RefObject<HTMLUListElement>;
    numOfCards: number;
    minCardSize?: number;
    maxCardSize?: number;
    minGridGap?: number;
};

function useGridCardSize(props: UseGridCardSizeProps) {
    const { containerRef, numOfCards, minCardSize = 250, maxCardSize = 350, minGridGap = 25 /* = 2rem */ } = props;
    const [cardSize, setCardSize] = useState(minCardSize);

    const calculateCardSize = useCallback(() => {
        const gridWidth = containerRef.current ? Math.ceil(containerRef.current.clientWidth + 1) : 0;

        if (gridWidth > 0) {
            const numOfItems = Math.floor(gridWidth / (minCardSize + minGridGap));
            const newCardSize = Math.floor(gridWidth / numOfItems - minGridGap - 1);

            if (newCardSize >= minCardSize) {
                setCardSize(Math.min(newCardSize, maxCardSize));
            }
        }
    }, [containerRef, minCardSize, maxCardSize, minGridGap]);

    useEffect(() => {
        if (!containerRef.current) return;

        if (numOfCards > 0) {
            calculateCardSize();
        }

        window.removeEventListener('resize', calculateCardSize);
        window.addEventListener('resize', calculateCardSize);

        return () => window.removeEventListener('resize', calculateCardSize);
    }, [containerRef, numOfCards, calculateCardSize]);

    return { cardSize };
}

export default useGridCardSize;
