import React, { useCallback, useEffect, useRef, useState } from 'react';

import classes from './Slider.module.css';

export type SliderProps = {
    minVal?: number;
    maxVal?: number;
    curVal?: number;

    sliderWidth?: string;
    sliderHeight?: number;

    sliderHandlerShape?: 'rect' | 'circle' | 'none';
    sliderProgressBarColor?: string;
    sliderHandlerColor?: string;
    sliderCustomCSS?: React.CSSProperties;

    onChange: (value: number, isDragged: boolean) => void;
};

function Slider(props: SliderProps) {
    const {
        minVal = 0,
        maxVal = 100,
        curVal,
        //sliderWidth = '100%',
        sliderHeight = 7,
        sliderHandlerShape = 'rect',
        sliderProgressBarColor = '#4dada8', //'#cc181e',
        sliderHandlerColor = '#fff',
        sliderCustomCSS,
        onChange
    } = props;

    const valuesRange = maxVal - minVal;

    const sliderElementRef = useRef<HTMLSpanElement | null>(null);
    const sliderHandlerElementRef = useRef<HTMLSpanElement | null>(null);
    const sliderHandlerWidth = useRef<number>(0);

    const isSliderActive = useRef<boolean>(false);
    const sliderValue = useRef<number>(Math.max(minVal, Math.min(curVal ?? 50, maxVal)));
    const sliderPos = useRef<number>(0);
    const handlerStyleRef = useRef<React.CSSProperties>({});
    const progressBarStyleRef = useRef<React.CSSProperties>({});

    const [isSliderReady, setIsSliderReady] = useState<boolean>(false);
    const setUpdateFlag = useState(false)[1];

    const sliderStyle: React.CSSProperties = { ...sliderCustomCSS, height: `${sliderHeight}px` };

    const initSilder = useCallback(() => {
        const sliderElement = sliderElementRef?.current ? (sliderElementRef.current as HTMLElement) : null;

        if (sliderElement) {
            const sliderWidth = sliderElement.getBoundingClientRect().width;
            const sliderStep = valuesRange / sliderWidth;
            sliderPos.current = sliderValue.current / sliderStep;

            let handlerWidth = 0;
            let handlerHeight = sliderHeight;

            if (sliderHandlerShape === 'rect') {
                handlerWidth = Math.min(30, sliderWidth > 10 ? Math.max(5, sliderWidth * 0.05) : sliderWidth * 0.05);
            } else if (sliderHandlerShape === 'circle') {
                handlerWidth = handlerHeight = sliderHeight * 1.5;
            }

            if (handlerWidth > 0) {
                sliderHandlerWidth.current = handlerWidth;

                handlerStyleRef.current = {
                    borderRadius: sliderHandlerShape === 'circle' ? '50%' : undefined,
                    top: sliderHandlerShape === 'circle' ? `${-(handlerWidth - sliderHeight) / 2}px` : undefined,
                    left: `${sliderPos.current - handlerWidth / 2}px`,
                    width: `${handlerWidth}px`,
                    height: `${handlerHeight}px`,
                    backgroundColor: sliderHandlerColor
                };
            }

            progressBarStyleRef.current = {
                width: `${sliderPos.current}px`,
                height: `${sliderHeight}px`,
                backgroundColor: sliderProgressBarColor
            };

            setIsSliderReady(true);
        }
    }, [sliderHandlerColor, sliderHandlerShape, sliderHeight, sliderProgressBarColor, valuesRange]);

    const updateSliderStyles = useCallback(() => {
        if (sliderHandlerWidth.current) {
            handlerStyleRef.current = {
                ...handlerStyleRef.current,
                left: `${sliderPos.current - sliderHandlerWidth.current / 2}px`
            };
        }

        progressBarStyleRef.current = { ...progressBarStyleRef.current, width: `${sliderPos.current}px` };
        setUpdateFlag(flag => !flag);
    }, [setUpdateFlag]);

    const onSilderMouseDown = (event: React.MouseEvent | React.TouchEvent) => {
        const sliderElement = sliderElementRef?.current ? (sliderElementRef.current as HTMLElement) : null;
        const sliderBoundingClientRect = sliderElement ? sliderElement.getBoundingClientRect() : null;
        const sliderWidth = sliderBoundingClientRect ? sliderBoundingClientRect.width : 0;

        const igonreEvent = (event as React.TouchEvent).touches ? false : (event as React.MouseEvent).button !== 0;
        const eventPageX = (event as React.TouchEvent).touches
            ? (event as React.TouchEvent).touches[0].pageX
            : (event as React.MouseEvent).pageX;

        if (
            sliderElement &&
            !igonreEvent &&
            sliderElement.offsetLeft <= eventPageX &&
            eventPageX <= sliderElement.offsetLeft + sliderWidth + 1
        ) {
            sliderPos.current = eventPageX - sliderElement.offsetLeft;
            sliderValue.current = sliderPos.current * (valuesRange / sliderWidth);
            isSliderActive.current = true;

            updateSliderStyles();
        }
    };

    const onSilderMouseUp = useCallback(() => {
        if (isSliderActive.current === true) {
            isSliderActive.current = false;
            onChange(sliderValue.current, false);
        }
    }, [onChange]);

    const onSilderMouseMove = useCallback(
        (event: MouseEvent | TouchEvent) => {
            const sliderElement = sliderElementRef?.current ? (sliderElementRef.current as HTMLElement) : null;

            if (sliderElement && isSliderActive.current) {
                const isTouchEvent = 'touches' in event;
                if (!isTouchEvent) event.preventDefault();

                const eventPageX = isTouchEvent ? (event as TouchEvent).touches[0].pageX : (event as MouseEvent).pageX;

                const sliderBoundingClientRect = sliderElement.getBoundingClientRect();
                const sliderWidth = sliderBoundingClientRect.width;

                sliderPos.current = eventPageX - sliderBoundingClientRect.left;
                sliderPos.current = Math.max(0, Math.min(sliderPos.current, sliderWidth));
                sliderValue.current = sliderPos.current * (valuesRange / sliderWidth);

                onChange(sliderValue.current, true);
                updateSliderStyles();
            }
        },
        [valuesRange, onChange, updateSliderStyles]
    );

    const onSilderKeyDown = useCallback(
        (event: React.KeyboardEvent) => {
            switch (event.key) {
                case 'ArrowLeft':
                case 'ArrowDown':
                    sliderValue.current = Math.max(minVal, sliderValue.current - 1);
                    break;
                case 'ArrowRight':
                case 'ArrowUp':
                    sliderValue.current = Math.min(maxVal, sliderValue.current + 1);
                    break;
                case 'Home':
                    sliderValue.current = minVal;
                    break;
                case 'End':
                    sliderValue.current = maxVal;
                    break;
                default:
                    return;
            }
            sliderPos.current = ((sliderValue.current - minVal) * (sliderElementRef.current?.getBoundingClientRect().width ?? 1)) / valuesRange;
            updateSliderStyles();
            onChange(sliderValue.current, false);
        },
        [minVal, maxVal, valuesRange, onChange, updateSliderStyles]
    );

    useEffect(() => {
        initSilder();
    }, [initSilder]);

    useEffect(() => {
        const sliderElement = sliderElementRef?.current ? (sliderElementRef.current as HTMLElement) : null;

        if (sliderElement && !isSliderActive?.current) {
            const sliderWidth = sliderElement.getBoundingClientRect().width;
            const sliderStep = valuesRange / sliderWidth;
            sliderPos.current = (curVal ?? 0) / sliderStep;
        }
    }, [curVal, valuesRange]);

    useEffect(() => {
        window.addEventListener('mousemove', onSilderMouseMove);
        window.addEventListener('mouseup', onSilderMouseUp);
        window.addEventListener('touchmove', onSilderMouseMove);
        window.addEventListener('touchend', onSilderMouseUp);

        return () => {
            window.removeEventListener('mousemove', onSilderMouseMove);
            window.removeEventListener('mouseup', onSilderMouseUp);
            window.removeEventListener('touchmove', onSilderMouseMove);
            window.removeEventListener('touchend', onSilderMouseUp);
        };
    }, [onSilderMouseMove, onSilderMouseUp]);

    return (
        <span
            ref={sliderElementRef}
            className={classes.slider}
            style={{ ...sliderStyle, visibility: isSliderReady ? 'visible' : 'hidden' }}
            onMouseDown={onSilderMouseDown}
            onTouchStart={(event: React.TouchEvent) => {
                if (event.touches.length !== 1) {
                    return;
                }
                onSilderMouseDown(event);
            }}
            /* Accessibility props */
            role="slider"
            aria-valuemin={minVal}
            aria-valuemax={maxVal}
            aria-valuenow={sliderValue.current}
            tabIndex={0}
            onKeyDown={onSilderKeyDown}
        >
            <span className={classes.sliderProgressBar} style={{ ...progressBarStyleRef.current, width: `${sliderPos.current}px` }}></span>
            {sliderHandlerShape !== 'none' && (
                <span
                    className={classes.sliderHandler}
                    style={{ ...handlerStyleRef.current, left: `${sliderPos.current - sliderHandlerWidth.current / 2}px` }}
                    ref={sliderHandlerElementRef}
                ></span>
            )}
        </span>
    );
}

export default Slider;
