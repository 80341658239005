import React, { useEffect, useRef, useState } from 'react';
import { useSearchParams } from 'react-router-dom';

import { ReleaseInfo } from '../../Types/ReleaseInfo';
import { BELLINO_BASE_URL, FormatDate, FetchPageData } from '../../Classes/Utilities';
import usePageMetdata from '../../hooks/usePageMetdata';
import useGridCardSize from '../../hooks/useGridCardSize';

import AppMainContainer from '../../Containers/AppMainContainer/AppMainContainer';
import Section from '../../Containers/Section/Section';
import Title from '../../Components/Title/Title';
import ReleaseCard from '../../Components/ReleaseCard/ReleaseCard';
import ToggleSelection from '../../Components/ToggleSelection/ToggleSelection';

import classes from './OnThisDay.module.css';

function OnThisDay(props: any) {
    usePageMetdata({ title: 'Released on This Day' });

    const [searchParams] = useSearchParams();
    const orderBy = searchParams.get('order') ?? 'popularity';

    const [releases, setReleases] = useState<ReleaseInfo[]>([]);

    const isInit = useRef(false);
    const isLoading = useRef(false);
    const thisDayReleasesListRef = useRef<HTMLUListElement>(null);

    const { cardSize } = useGridCardSize({
        containerRef: thisDayReleasesListRef,
        numOfCards: releases.length
    });

    const sortReleases = (releasesArr: ReleaseInfo[], orderBy: string = 'popularity') => {
        return [...releasesArr].sort((a, b) =>
            orderBy === 'date' ? new Date(b.date).getFullYear() - new Date(a.date).getFullYear() : (b.score ?? 0) - (a.score ?? 0)
        );
    };

    useEffect(() => {
        if (!isLoading.current && !isInit.current) {
            isLoading.current = true;
            setReleases([]);

            FetchPageData(`${BELLINO_BASE_URL}/api/pages/onthisday`).then((response: any) => {
                if (response.success && response.result?.thisDayReleases) {
                    const releases = response.result?.thisDayReleases?.map((release: any) => {
                        return {
                            id: release.id,
                            name: release.name,
                            date: FormatDate(release.date, 'Short'),
                            genres: release.genres ?? [],
                            imgPath: release.imgPath,
                            artists: release.artists ?? [],
                            score: release.score ?? 0
                        } as ReleaseInfo;
                    });
                    setReleases(sortReleases(releases, orderBy));
                    isLoading.current = false;
                    isInit.current = true;
                }

                isLoading.current = false;
            });
        }
    }, [orderBy]);

    useEffect(() => {
        setReleases(prev => sortReleases(prev, orderBy));
    }, [orderBy]);

    return (
        <AppMainContainer backgroundColor="off-white" showPreloader={isLoading.current && !isInit.current}>
            {
                <Section>
                    <Title>Released on This Day</Title>
                    <div className="mb-100">
                        <ToggleSelection
                            selectedValue={orderBy === 'popularity' ? 'By Popularity' : 'By Date'}
                            optionL={{
                                href: '/onthisday?order=popularity',
                                value: 'By Popularity'
                            }}
                            optionR={{ href: '/onthisday?order=date', value: 'By Date' }}
                        ></ToggleSelection>
                    </div>
                    <ul ref={thisDayReleasesListRef} className={classes.releasesGrid}>
                        {releases?.map(release => (
                            <li key={release.id}>
                                <ReleaseCard cardSize={cardSize} releaseInfo={release} showArtists={true}></ReleaseCard>
                            </li>
                        ))}
                    </ul>
                </Section>
            }
        </AppMainContainer>
    );
}

export default OnThisDay;
