import React, { useState } from 'react';
import { Link } from 'react-router-dom';

import Image from '../Image/Image';

import classes from './Card.module.css';

export type CardImageOverlay = {
    showMode: 'always' | 'onHover' | 'nonHover';
    href?: string;
    className?: string;
    overlayChildren: any;
};

export type CardProps = {
    cardSize?: 'large' | 'medium' | 'small' | number;
    cardShape?: 'square' | 'rect';
    cardRoundStyle?: 'rounded' | 'top-rounded' | 'none';
    imgPath: string;
    imgOverlays?: CardImageOverlay[];
    details?: any;
    detailsCustomCSS?: React.CSSProperties;
    ribbonText?: string;
    ribbonColor?: 'red' | 'purple' | 'blue' | 'yellow' | 'gray';
    onImageClick?: () => void;
};

function Card(props: CardProps) {
    const { cardSize = 'medium', imgPath, imgOverlays, details, detailsCustomCSS, ribbonText, ribbonColor = 'red', onImageClick } = props;

    const [isMouseOver, setIsMouseOver] = useState(false);

    const cardShape = props.cardShape ?? 'square';
    const cardRoundStyle = props.cardRoundStyle === 'none' ? '' : props.cardRoundStyle ?? 'rounded';
    const cardSizeX = cardSize === 'large' ? 320 : cardSize === 'medium' ? 250 : cardSize === 'small' ? 200 : cardSize ?? 200;
    const cardSizeY = cardShape === 'square' ? cardSizeX : cardSizeX / 1.77777777;

    const cardContainerStyle = {
        width: `${cardSizeX}px`
    };

    return (
        <div
            className={classes.container}
            style={cardContainerStyle}
            onMouseEnter={() => {
                setIsMouseOver(true);
            }}
            onMouseLeave={() => {
                setIsMouseOver(false);
            }}
        >
            <Image
                src={imgPath}
                viewWidth={cardSizeX}
                viewHeight={cardSizeY}
                viewMode="background-image"
                imageClass={`${classes.cardImage} ${cardRoundStyle} l-shadow`}
                onImageClick={onImageClick}
            >
                {ribbonText && <div className={`${classes.ribbon} ${classes[`${ribbonColor}Ribbon`]}`}>{ribbonText}</div>}
                {imgOverlays &&
                    imgOverlays.map((imgOverlay, index) => {
                        const isVisible =
                            imgOverlay.showMode === 'always' ||
                            (isMouseOver && imgOverlay.showMode === 'onHover') ||
                            (!isMouseOver && imgOverlay.showMode === 'nonHover');

                        return imgOverlay.href ? (
                            <Link
                                key={index}
                                to={imgOverlay.href}
                                className={imgOverlay.className ?? 'img-overlay'}
                                style={{ display: isVisible ? `block` : 'none' }}
                            >
                                {imgOverlay.overlayChildren}
                            </Link>
                        ) : (
                            <div key={index} className={imgOverlay.className ?? 'img-overlay'} style={{ display: isVisible ? `block` : 'none' }}>
                                {imgOverlay.overlayChildren}
                            </div>
                        );
                    })}
            </Image>
            {details && (
                <div className={classes.cardDetails} style={detailsCustomCSS}>
                    {details}
                </div>
            )}
        </div>
    );
}

export default Card;
