import React, { useEffect, useMemo, useRef, useState } from 'react';

import { BELLINO_BASE_URL, FetchPageData } from '../../Classes/Utilities';

import usePageMetdata from '../../hooks/usePageMetdata';

import AppMainContainer from '../../Containers/AppMainContainer/AppMainContainer';
import UserAvatar from '../../Components/UserAvatar/UserAvatar';
import SliderList from '../../Containers/SliderList/SliderList';
import Title from '../../Components/Title/Title';
import Link from '../../Components/Link/Link';
import Section from '../../Containers/Section/Section';
import PlaylistCard from '../../Components/PlaylistCard/PlaylistCard';
import ReleaseCard from '../../Components/ReleaseCard/ReleaseCard';
import GenericList, { GenericListItem } from '../../Components/GenericList/GenericList';
import TrackMenuButton from '../../Components/MenuButton/TrackMenuButton';
import ItemPlaybar from '../../Components/ItemPlaybar/ItemPlaybar';
import ArtistLink from '../../Components/ArtistLink/ArtistLink';

import { UserInfo } from '../../Types/User';
import { ReleaseInfo } from '../../Types/ReleaseInfo';
import { Track } from '../../Classes/Track';
import { TrackInfo } from '../../Types/Track';

//import classes from './Home.module.css';

function Home(props: any) {
    usePageMetdata({ title: 'Home' });

    const isLoading = useRef<boolean>();

    const [topTrendingTracks, setTopTrendingTracks] = useState<TrackInfo[]>([]);
    const [newReleases, setNewReleases] = useState<ReleaseInfo[]>([]);
    const [sameDateReleases, setSameDateReleases] = useState<ReleaseInfo[]>([]);
    const [users, setUsers] = useState<UserInfo[]>([]);
    const [isWideScreen, setIsWideScreen] = useState<boolean>(false);

    useEffect(() => {
        if (!isLoading.current) {
            isLoading.current = true;

            FetchPageData(`${BELLINO_BASE_URL}/api/pages/home`).then((response: any) => {
                if (response.success) {
                    setTopTrendingTracks(response.result?.topTrendingTracks);
                    setNewReleases(response.result?.newReleases);
                    setSameDateReleases(response.result?.sameDateReleases);
                    setUsers(response.result?.users);
                } else {
                    setTopTrendingTracks([]);
                }

                isLoading.current = false;
            });
        }
    }, []);

    useEffect(() => {
        const handleResize = () => {
            setIsWideScreen(window.innerWidth > 920);
        };
        handleResize();
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const tracksAsListItems = useMemo(
        () =>
            topTrendingTracks.map(trackInfo => {
                const track = new Track(trackInfo);

                return {
                    titleStr: track.fullName,
                    title: <Link href={`/track?tid=${track.id}`}>{track.name}</Link>,
                    subTitle: <ArtistLink artists={track?.artists}></ArtistLink>,
                    image: track.coverImgPath,
                    imageHref: `/track?tid=${track.id}`,
                    //startSection: <div className="fs-160 ms-100">{track.chartInfo?.position}</div>,
                    endSection: (
                        <div className="text-dark-gray">
                            <ItemPlaybar
                                viewMode="inline"
                                viewSize="medium"
                                itemType="Track"
                                itemId={track.id}
                                children={<TrackMenuButton track={track} buttonView="transparent"></TrackMenuButton>}
                            ></ItemPlaybar>
                        </div>
                    )
                } as GenericListItem;
            }),
        [topTrendingTracks]
    );

    const nowYear = new Date().getFullYear();
    const minYear = new Date().getMonth() < 6 ? nowYear - 1 : nowYear;

    return (
        <AppMainContainer backgroundColor="off-white" showPreloader={isLoading.current !== false}>
            <Section>
                <Title>
                    <Link href={`/charts?minYear=${minYear}`} viewMode="See-More-Hover">
                        Top Trending Tracks
                    </Link>
                </Title>
                <div>
                    <GenericList
                        items={tracksAsListItems}
                        viewSize="medium"
                        spaceBetweenItems={false}
                        listCustomCSS={{ display: 'grid', gridTemplateColumns: isWideScreen ? '1fr 1fr' : '1fr', columnGap: '2rem' }}
                    ></GenericList>
                </div>
            </Section>

            <Section>
                <Title>
                    <Link href={'/radio'} viewMode="See-More-Hover">
                        Playlists By Decades
                    </Link>
                </Title>
                <SliderList>
                    <PlaylistCard imgName={'modern'} title="Now Hits" minYear={2024}></PlaylistCard>
                    <PlaylistCard imgName={'timetunnel'} title="All Time Hits"></PlaylistCard>
                    <PlaylistCard imgName={'2020s'} title="Best of 2020's" minYear={2020} maxYear={2029}></PlaylistCard>
                    <PlaylistCard imgName={'2010s'} title="Best of 2010's" minYear={2010} maxYear={2019}></PlaylistCard>
                    <PlaylistCard imgName={'2000s'} title="Best of 2000's" minYear={2000} maxYear={2009}></PlaylistCard>
                    <PlaylistCard imgName={'90s'} title="Best of 1990's" minYear={1990} maxYear={1999}></PlaylistCard>
                    <PlaylistCard imgName={'80s'} title="Best of 1980's" minYear={1980} maxYear={1989}></PlaylistCard>
                    <PlaylistCard imgName={'70s'} title="Best of 1970's" minYear={1970} maxYear={1979}></PlaylistCard>
                    <PlaylistCard imgName={'60s'} title="Best of 1960's" minYear={1960} maxYear={1969}></PlaylistCard>
                </SliderList>
            </Section>

            <Section>
                <Title>
                    <Link href={'/radio'} viewMode="See-More-Hover">
                        Playlists By Genres
                    </Link>
                </Title>
                <SliderList>
                    <PlaylistCard imgName={'pop'} title="Pop Hits" genres={['Pop']}></PlaylistCard>
                    <PlaylistCard imgName={'rock'} title="Rock Hits" genres={['Rock', 'Classic Rock', 'Heavy Rock']}></PlaylistCard>
                    <PlaylistCard imgName={'soul'} title="Soul Hits" genres={['Soul']}></PlaylistCard>
                    <PlaylistCard imgName={'indie'} title="Indie Hits" genres={['Indie', 'Indie Rock', 'Indie Pop']}></PlaylistCard>
                    <PlaylistCard imgName={'rap'} title="Hip Hop Hits" genres={['Hip Hop']}></PlaylistCard>
                    <PlaylistCard imgName={'rnb'} title="R&B Hits" genres={['R&B', 'ryhythm and blues']}></PlaylistCard>
                    <PlaylistCard imgName={'folk'} title="Folk Hits" genres={['Folk', 'Folk Rock']}></PlaylistCard>
                    <PlaylistCard imgName={'classical'} title="Classical Hits" genres={['Classical']}></PlaylistCard>
                    <PlaylistCard imgName={'reggae'} title="Reggae Hits" genres={['Reggae']}></PlaylistCard>
                    <PlaylistCard imgName={'electronic'} title="Electronic Hits" genres={['electronic']}></PlaylistCard>
                    <PlaylistCard imgName={'metal'} title="Metal Hits" genres={['Metal', 'Heavy Metal']}></PlaylistCard>
                    <PlaylistCard imgName={'acoustic'} title="Acoustic" genres={['Acoustic']}></PlaylistCard>
                    <PlaylistCard imgName={'oldies'} title="Oldies" minYear={1940} maxYear={1969}></PlaylistCard>
                    <PlaylistCard imgName={'deep-house'} title="Deep House" genres={['Deep House']}></PlaylistCard>
                    <PlaylistCard imgName={'synthpop'} title="Synthpop Hits" genres={['Synthpop']}></PlaylistCard>
                    <PlaylistCard imgName={'ballad'} title="Ballads" genres={['Ballad']}></PlaylistCard>
                    <PlaylistCard imgName={'jazz'} title="Jazz Hits" genres={['Jazz']}></PlaylistCard>
                </SliderList>
            </Section>

            <Section>
                <Title>
                    <Link href={'/radio'} viewMode="See-More-Hover">
                        Playlists By Mood
                    </Link>
                </Title>
                <SliderList>
                    {/*<PlaylistCard imgName={'christmas'} title="Christmas" tagName="Christmas"></PlaylistCard>*/}
                    <PlaylistCard imgName={'party'} title="Party" tagName="Party"></PlaylistCard>
                    <PlaylistCard imgName={'happy'} title="Happy" tagName="Happiness"></PlaylistCard>
                    <PlaylistCard imgName={'love'} title="Love" tagName="Love"></PlaylistCard>
                    <PlaylistCard imgName={'relax'} title="Relaxing" tagName="Relaxing"></PlaylistCard>
                    <PlaylistCard imgName={'sad'} title="Sadness" tagName="Sadness"></PlaylistCard>
                    <PlaylistCard imgName={'sad2'} title="Melancholy" tagName="Melancholy"></PlaylistCard>
                    <PlaylistCard imgName={'emotional'} title="Emotional" tagName="Emotional"></PlaylistCard>
                    <PlaylistCard imgName={'sleep'} title="Sleeping" tagName="Sleeping"></PlaylistCard>
                    <PlaylistCard imgName={'freedom'} title="Freedom" tagName="Freedom"></PlaylistCard>
                    <PlaylistCard imgName={'Loneliness'} title="Loneliness" tagName="Loneliness"></PlaylistCard>
                    <PlaylistCard imgName={'Goodbye'} title="Goodbye" tagName="Goodbye"></PlaylistCard>
                    <PlaylistCard imgName={'sensual'} title="Sensual" tagName="Sensual"></PlaylistCard>
                </SliderList>
            </Section>

            <Section>
                <Title>
                    <Link href={'/newreleases'} viewMode="See-More-Hover">
                        New Releases
                    </Link>
                </Title>
                <SliderList>
                    {newReleases.map(release => (
                        <ReleaseCard key={release.id} releaseInfo={release} showArtists={true}></ReleaseCard>
                    ))}
                </SliderList>
            </Section>

            {sameDateReleases.length > 0 && (
                <Section>
                    <Title>
                        <Link href={'/onthisday'} viewMode="See-More-Hover">
                            Released on This Day
                        </Link>
                    </Title>
                    <SliderList>
                        {sameDateReleases.map(release => (
                            <ReleaseCard key={release.id} releaseInfo={release} showArtists={true}></ReleaseCard>
                        ))}
                    </SliderList>
                </Section>
            )}

            <Section>
                <Title>
                    <Link href={'/users'} viewMode="See-More-Hover">
                        Bellino Users
                    </Link>
                </Title>
                <SliderList>
                    {(users as UserInfo[]).map(user => (
                        <UserAvatar key={user.id} userInfo={user} viewMode="DetailedBox"></UserAvatar>
                    ))}
                </SliderList>
            </Section>
        </AppMainContainer>
    );
}

export default Home;
