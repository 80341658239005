import { Gender, UserInfo } from '../Types/User';
import { PhotoInfo } from '../Types/Photo';
import { DEFAULT_AVATAR_IMG, GetAgeByDate, getImageFullPath } from './Utilities';
import { getUser } from '../Services/User';

export class User {
    public isInit: boolean = false;
    public isUserLoaded: boolean = false;

    public rawUserInfo?: UserInfo;
    public id: string = '';
    public firstName: string = '';
    public lastName: string = '';
    public fullName: string = '';
    public gender?: Gender;
    public email: string = '';
    public userImg: string = DEFAULT_AVATAR_IMG;
    public userPhotos?: PhotoInfo[];
    public userURL: string = '';
    public userDetails?: string;
    public userBirthday?: Date;
    public userAge?: number;
    public userCountry?: string;
    public userLocation?: string;
    public userFullLocation?: string;
    public registrationDate?: Date;

    public constructor(userInfo?: UserInfo, fetchUserData: boolean = false) {
        if (fetchUserData) {
            this.LoadUser();
        } else {
            this.init(userInfo);
        }
    }

    public init(userInfo?: UserInfo) {
        if (userInfo) {
            this.rawUserInfo = userInfo;
            this.id = userInfo.id;
            this.firstName = userInfo.firstName;
            this.lastName = userInfo.lastName;
            this.fullName = `${userInfo.firstName} ${userInfo.lastName}`;
            this.userImg = getImageFullPath(userInfo.imgPath, 'User');
            this.userPhotos = userInfo.photos ? userInfo.photos : userInfo.imgPath ? [{ src: userInfo.imgPath }] : [];
            this.email = userInfo.email ?? '';
            this.gender = userInfo.gender;
            this.userCountry = userInfo.country;
            this.userLocation = userInfo.location;
            this.userFullLocation = userInfo.country && userInfo.location ? [userInfo.country, userInfo.location].join(', ') : userInfo.country;
            this.userBirthday = userInfo.birthday ? new Date(userInfo.birthday) : undefined;
            this.userAge = GetAgeByDate(userInfo.birthday);
            this.userURL = `/user?uid=${userInfo.id}`;
            this.registrationDate = userInfo.registrationDate ? new Date(userInfo.registrationDate) : undefined;

            if (this.userAge || userInfo.gender || userInfo.country) {
                const userLocation = [userInfo.location, userInfo.country].filter(d => d).join(', ');
                let userDetailsArray = [this.userAge, userInfo.gender, userLocation].filter(d => d);
                this.userDetails = userDetailsArray.join(' • ');
            }

            this.userPhotos.forEach(userPhoto => {
                userPhoto.src = getImageFullPath(userPhoto.src, 'User');
            });

            this.isInit = true;
        }
    }

    public async LoadUser() {
        if (!this.isUserLoaded) {
            this.init(await getUser());
            this.isUserLoaded = true;
        }
    }
}
