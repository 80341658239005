import { AlbumTrack } from '../Types/Album';
import { ArtistInfo } from '../Types/Artist';
import { PhotoInfo } from '../Types/Photo';
import {
    DEFAULT_RELEASE_IMG,
    BuildAmazonBuyLink,
    BuildTrackFullName,
    getImageFullPath,
    SecondsToTime,
    IsSameDayButYear,
    GetAgeByDate
} from './Utilities';

type cdTracksInfo = {
    number: number;
    tracks: AlbumTrack[];
    totalDurationSec: number;
    totalDurationStr: string;
};

export class Album {
    public isInit: boolean = false;
    public id: string = '';
    public name: string = '';
    public fullName: string = '';
    public date: string = '';
    public coverImgPath: string = DEFAULT_RELEASE_IMG;
    public genres: string[] = [];
    public artists: ArtistInfo[] = [];
    public tracks: AlbumTrack[] = [];
    public photos: PhotoInfo[] = [];
    public cdTracksInfo: cdTracksInfo[] = [];
    public totalDurationSec: number = 0;
    public totalDurationStr: string = '';
    public amazonBuyLink: string = '';
    public isReleasedOnThisDay: boolean = false;
    public isVariousArtistsAlbum: boolean = false;
    public age?: number;

    public constructor(info?: any) {
        this.init(info);
    }

    public init(info: any) {
        if (info?.id) {
            this.id = info.id;
            this.name = info.name;
            this.date = info.date;
            this.genres = info.genres?.filter(Boolean) ?? [];
            this.artists = info.artists;
            this.photos = info.photos;
            this.fullName = BuildTrackFullName(this.name, this.artists);
            this.coverImgPath = getImageFullPath(info?.coverImgPath ?? info.imgPath, 'Album');
            this.amazonBuyLink = BuildAmazonBuyLink(this.fullName);

            if (this.date) {
                this.age = GetAgeByDate(this.date);
                this.isReleasedOnThisDay = IsSameDayButYear(new Date(this.date), new Date());
            }

            info.tracks?.forEach((track: any) => {
                const albumTrack: AlbumTrack = {
                    albumId: this.id,
                    id: track.id,
                    name: track.name,
                    cdNumber: track?.cdNumber ?? 1,
                    position: track?.position,
                    duration: track?.duration ?? 0,
                    score: track?.score ?? 0,
                    artists: track?.artists ?? [],
                    videos: track?.video ? [track.video] : undefined
                };

                if (track?.duration) {
                    this.totalDurationSec += track.duration;
                    albumTrack.duration = track.duration;
                    albumTrack.durationStr = SecondsToTime(track.duration);
                }

                this.tracks.push(albumTrack);
            });

            this.tracks
                .reduce(
                    (cdTracks, e) => cdTracks.set(e.cdNumber ?? 1, [...(cdTracks.get(e.cdNumber ?? 1) || []), e]),
                    new Map<number, AlbumTrack[]>()
                )
                .forEach((cdTracks, cdNumber) => {
                    const cdTotalDurationSec = Object.values(cdTracks).reduce((a, b) => a + b.duration, 0);

                    this.cdTracksInfo.push({
                        number: cdNumber,
                        tracks: cdTracks,
                        totalDurationSec: cdTotalDurationSec,
                        totalDurationStr: SecondsToTime(cdTotalDurationSec)
                    });
                });

            this.isVariousArtistsAlbum = Boolean(this.artists?.filter(a => a.id?.toString() === '0')?.length);
            this.totalDurationStr = SecondsToTime(this.totalDurationSec);
            this.isInit = true;
        }
    }
}
