import React, { useMemo, useRef } from 'react';

import { VideoInfo } from '../../../Types/VideoInfo';
import { extractVideoID, getYoutubeVideoData } from '../../../Services/External/Google';

import Dialog from '../../../Containers/Dialog/Dialog';
import Button from '../../../Components/Button/Button';
import Link from '../../../Components/Link/Link';

import classes from './AddVideoDialog.module.css';

type AddVideoDialogProps = {
    trackFullName: string;
    trackVideos: VideoInfo[];
    isVisible: boolean;
    isAdmin: boolean;
    onCloseDialog: () => void;
    onAdd: (videoExternalId: string, videoType: string, videoDuration: number) => void;
};

function AddVideoDialog(props: AddVideoDialogProps) {
    const { trackFullName, trackVideos, isVisible, isAdmin = false, onCloseDialog, onAdd } = props;

    const inputElementRef = useRef(null);
    const videoTypeSelectElementRef = useRef(null);
    const warningElementRef = useRef(null);

    const onAddVideo = async () => {
        const inputElement = inputElementRef?.current ? (inputElementRef.current as HTMLInputElement) : null;
        const videoTypeSelectElement = videoTypeSelectElementRef?.current ? (videoTypeSelectElementRef.current as HTMLSelectElement) : null;

        if (inputElement?.value && inputElement.value.trim().length > 0 && videoTypeSelectElement?.value) {
            const videoYTID = extractVideoID(inputElement.value);
            const isAlreadyExist = Boolean(trackVideos?.filter(tv => tv.externalId === videoYTID)?.length);

            if (warningElementRef.current && (!videoYTID || !videoYTID.trim().length)) {
                (warningElementRef.current as HTMLElement).innerHTML = 'Invalid video link! Please try again.';
            } else if (warningElementRef.current && isAlreadyExist) {
                (warningElementRef.current as HTMLElement).innerHTML = 'Video already exist in our data base. You may add another one.';
            } else if (videoYTID) {
                const videoData = await getYoutubeVideoData(videoYTID);

                if (videoData?.duration?.total_seconds || isAdmin) {
                    onAdd(videoYTID, videoTypeSelectElement.value, videoData?.duration?.total_seconds || 0);
                    onCloseDialog();
                    setTimeout(() => {
                        inputElement.value = '';
                    }, 100);
                } else if (warningElementRef.current) {
                    (warningElementRef.current as HTMLElement).innerHTML = 'Invalid video link! Please try again.';
                }
            }
        } else {
            inputElement?.focus();
        }
    };

    const youtubeSearchQuery = useMemo(
        () => `https://www.youtube.com/results?search_query=${encodeURIComponent(trackFullName)}`,
        [trackFullName]
    );

    return (
        <Dialog isVisible={isVisible} title="Add Video" onCloseDialog={onCloseDialog}>
            <p className="mb-100">To add video, just enter the video’s link:</p>
            <input ref={inputElementRef} className={classes.videoInput} placeholder="https://www.youtube.com/..."></input>
            <div ref={warningElementRef} className={classes.warning}>
                <b className="mb-50">Warning:</b>
                <p>
                    Please do not abuse this system! Adding unrelevant videos for this item may result in you being banned from adding videos, or
                    from the entire site.
                </p>
            </div>
            <div className={classes.footer}>
                <div>
                    <Link target="_blank" textColor="dark-blue" href={youtubeSearchQuery}>
                        Search Videos on YouTube
                    </Link>
                </div>
                <div>
                    <select ref={videoTypeSelectElementRef} className="me-100" style={{ width: '90px' }}>
                        <option>Official</option>
                        <option>Unofficial</option>
                        <option>Live</option>
                    </select>
                    <Button viewMode="fancy" onClick={onAddVideo}>
                        Add
                    </Button>
                </div>
            </div>
        </Dialog>
    );
}

export default AddVideoDialog;
