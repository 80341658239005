import { PlaylistInfo } from '../Types/PlaylistInfo';
import { RequestResponse } from '../Types/RequestResponse';
import { BaseUserInfo, UserInfo, UserSessionData } from '../Types/User';
import { Message } from '../Types/Chat';

import { SearchItemInfo } from '../Components/MultiSearchSelection/MultiSearchSelection';

import { BELLINO_BASE_URL, capitalizeFirstLetter, GetFixedForamtDate, getImageFullPath } from '../Classes/Utilities';
import { deleteMessage } from './Messages';

/* Signin/Signup/Account API */

export const getUserSessionInfo = async () => {
    let userData: UserSessionData | undefined = undefined;

    try {
        const response = await fetch(`${BELLINO_BASE_URL}/api/users?action=getusersession`, {
            method: 'GET'
        });

        if (response.ok) {
            const responseData = await response.json();

            if (responseData?.success) {
                userData = responseData.result as UserSessionData;

                if (userData.userInfo?.id) {
                    userData.userInfo.id = userData.userInfo.id.toString();
                }
            }
        } else {
            console.error('Network response was not ok');
        }
    } catch (error) {
        console.error('Fetch error:', error);
    }

    return userData;
};

export const logoutUser = async () => {
    let isSuccess: boolean = false;

    try {
        const response = await fetch(`${BELLINO_BASE_URL}/api/users`, {
            method: 'POST',
            body: JSON.stringify({ action: 'logout' })
        });

        if (response.ok) {
            const responseData = await response.json();

            if (responseData?.success) {
                isSuccess = Boolean(responseData.result);
            }
        } else {
            console.error('Network response was not ok');
        }
    } catch (error) {
        console.error('Fetch error:', error);
    }

    return isSuccess;
};

export const signinUser = async (email: string, password: string) => {
    let userData: UserSessionData | undefined = undefined;

    if (email.trim().length > 0 && password.trim().length > 0) {
        try {
            const response = await fetch(`${BELLINO_BASE_URL}/api/users`, {
                method: 'POST',
                body: JSON.stringify({
                    action: 'signin',
                    pass: password,
                    email
                })
            });

            if (response.ok) {
                const responseData = await response.json();

                if (responseData?.success) {
                    userData = {
                        isReady: true,
                        sessionId: responseData.result.SessionID,
                        userInfo: {
                            id: responseData.result.ID,
                            firstName: responseData.result.FirstName,
                            lastName: responseData.result.LastName,
                            email,
                            imgPath: getImageFullPath(responseData.result.ProfileImage, 'User')
                        }
                    } as UserSessionData;
                }
            } else {
                console.error('Network response was not ok');
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    return userData;
};

export const signupUser = async (firstName: string, lastName: string, email: string, password: string, recaptchaToken: string) => {
    let userData: UserSessionData | undefined = undefined;

    if (firstName.trim().length > 0 && lastName.trim().length > 0 && email.trim().length > 0 && password.trim().length > 0) {
        try {
            const response = await fetch(`${BELLINO_BASE_URL}/api/users`, {
                method: 'POST',
                body: JSON.stringify({
                    action: 'signup',
                    fname: firstName,
                    lname: lastName,
                    pass: password,
                    email,
                    recaptchaToken
                })
            });

            if (response.ok) {
                const responseData = await response.json();

                if (responseData?.success) {
                    userData = {
                        isReady: true,
                        sessionId: responseData.result.SessionID,
                        userInfo: {
                            id: responseData.result.ID,
                            firstName,
                            lastName,
                            email,
                            imgPath: getImageFullPath(responseData.result.ProfileImage)
                        }
                    } as UserSessionData;
                }
            } else {
                console.error('Network response was not ok');
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    return userData;
};

export const resetUserPassword = async (email: string) => {
    let result: RequestResponse | undefined;

    if (email.trim().length > 0) {
        try {
            const response = await fetch(`${BELLINO_BASE_URL}/api/users`, {
                method: 'POST',
                body: JSON.stringify({
                    action: 'resetpassword',
                    email
                })
            });

            if (response.ok) {
                result = await response.json();
            } else {
                console.error('Network response was not ok');
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    return result;
};

export const signinFacebookUser = async (fbUser: any) => {
    let userData: UserSessionData | undefined = undefined;

    if (fbUser?.id) {
        try {
            const response = await fetch(`${BELLINO_BASE_URL}/api/users`, {
                method: 'POST',
                body: JSON.stringify({
                    action: 'signinFacebookUser',
                    fbuid: fbUser.id,
                    fname: fbUser.first_name,
                    lname: fbUser.last_name,
                    imgPath: fbUser.picture?.data?.url ?? `https://graph.facebook.com/${fbUser.id}/picture?type=large`,
                    email: fbUser.email,
                    gender: fbUser.gender ? capitalizeFirstLetter(fbUser.gender) : '',
                    homePage: fbUser.link ?? '',
                    country: fbUser.location?.location?.country ?? '',
                    city: fbUser.location?.location?.city ?? ''
                })
            });

            if (response.ok) {
                const responseData = await response.json();

                if (responseData?.success) {
                    userData = responseData.result as UserSessionData;
                }
            } else {
                console.error('Network response was not ok');
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    return userData;
};

export const signinGoogleUser = async (googleUser: any) => {
    let userData: UserSessionData | undefined = undefined;

    if (googleUser?.sub) {
        try {
            const response = await fetch(`${BELLINO_BASE_URL}/api/users`, {
                method: 'POST',
                body: JSON.stringify({
                    action: 'signinGoogleUser',
                    googleId: googleUser.sub,
                    fname: googleUser.given_name,
                    lname: googleUser.family_name || '',
                    imgPath: googleUser.picture,
                    email: googleUser.email
                    /*gender: fbUser.gender ? capitalizeFirstLetter(fbUser.gender) : '',
                    homePage: fbUser.link ?? '',
                    country: fbUser.location?.location?.country ?? '',
                    city: fbUser.location?.location?.city ?? ''*/
                })
            });

            if (response.ok) {
                const responseData = await response.json();

                if (responseData?.success) {
                    userData = responseData.result as UserSessionData;
                }
            } else {
                console.error('Network response was not ok');
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    return userData;
};

export const updateUserDetails = async (userInfo: UserInfo) => {
    let isSuccess: boolean = false;

    if (userInfo.firstName.length > 0 && userInfo.lastName.length > 0 && userInfo.email?.length) {
        const formData = new FormData();
        formData.append('action', 'SaveUserDetails');
        formData.append('fname', userInfo.firstName);
        formData.append('lname', userInfo.lastName);
        formData.append('email', userInfo.email);

        if (userInfo.gender) {
            formData.append('gender', userInfo.gender);
        }

        if (userInfo.birthday) {
            const bdate = GetFixedForamtDate(userInfo.birthday);
            formData.append('bDay', bdate.getDate().toString());
            formData.append('bMonth', (bdate.getMonth() + 1).toString());
            formData.append('bYear', bdate.getFullYear().toString());
        }

        if (userInfo.country) {
            formData.append('cntry', userInfo.country);
        }

        if (userInfo.location) {
            formData.append('lctn', userInfo.location);
        }

        if (userInfo.homePage) {
            formData.append('hmpg', userInfo.homePage);
        }

        try {
            const response = await fetch(`${BELLINO_BASE_URL}/ProcessAjaxRequest`, {
                method: 'POST',
                body: formData
            });

            if (response.ok) {
                const responseData = await response.text();

                if (responseData) {
                    isSuccess = true;
                }
            } else {
                console.error('Network response was not ok');
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    return isSuccess;
};

export const updateUserPassword = async (oldPassword: string, newPassword: string) => {
    let isSuccess: boolean = false;

    if (oldPassword.length > 0 && newPassword.length > 0) {
        const formData = new FormData();
        formData.append('action', 'UpdateUserPassword');
        formData.append('pass', oldPassword);
        formData.append('passN', newPassword);

        try {
            const response = await fetch(`${BELLINO_BASE_URL}/ProcessAjaxRequest`, {
                method: 'POST',
                body: formData
            });

            if (response.ok) {
                const responseData = await response.text();

                if (responseData) {
                    isSuccess = parseInt(responseData) === 1;
                }
            } else {
                console.error('Network response was not ok');
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    return isSuccess;
};

/* Friendship API */

export const cancelFriendship = async (friendD: string) => {
    let isSuccess: boolean = false;

    if (friendD.toString().length > 0) {
        const formData = new FormData();
        formData.append('action', 'CancelFriendship');
        formData.append('fid', friendD);

        try {
            const response = await fetch(`${BELLINO_BASE_URL}/ProcessAjaxRequest`, {
                method: 'POST',
                body: formData
            });

            if (response.ok) {
                const responseData = await response.text();
                isSuccess = Boolean(responseData === '1' || responseData.toLowerCase() === 'true');
            } else {
                console.error('Network response was not ok');
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    return isSuccess;
};

export const cancelFriendshipRequest = async (friendD: string, msgID?: string) => {
    let isSuccess: boolean = false;

    if (msgID?.toString().length) {
        isSuccess = await deleteMessage(msgID);
    } else if (friendD.toString().length) {
        const formData = new FormData();
        formData.append('action', 'CancelFR');
        formData.append('uid', friendD);

        try {
            const response = await fetch(`${BELLINO_BASE_URL}/ProcessAjaxRequest`, {
                method: 'POST',
                body: formData
            });

            if (response.ok) {
                const responseData = await response.text();
                isSuccess = Boolean(responseData);
            } else {
                console.error('Network response was not ok');
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    return isSuccess;
};

export const acceptFriendshipRequest = async (friendD: string, msgID: string) => {
    let isSuccess: boolean = false;

    if (friendD.toString().length > 0) {
        try {
            const response = await fetch(`${BELLINO_BASE_URL}/ProcessAjaxRequest?action=AddFriend&fid=${friendD}`, {
                method: 'Get'
            });

            if (response.ok) {
                const responseData = await response.text();
                isSuccess = Boolean(responseData);

                if (isSuccess) {
                    await deleteMessage(msgID);
                }
            } else {
                console.error('Network response was not ok');
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    return isSuccess;
};

export const sendFriendshipRequest = async (friendD: string) => {
    let isSuccess: boolean = false;

    if (friendD.toString().length > 0) {
        const formData = new FormData();
        formData.append('action', 'SendFR');
        formData.append('uid', friendD);

        try {
            const response = await fetch(`${BELLINO_BASE_URL}/ProcessAjaxRequest`, {
                method: 'POST',
                body: formData
            });

            if (response.ok) {
                const responseData = await response.text();
                isSuccess = Boolean(responseData === '1' || responseData.toLowerCase() === 'true');
            } else {
                console.error('Network response was not ok');
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    return isSuccess;
};

export const getUserFriendsIDS = async () => {
    let userFriendsIDS: {
        friendsIds: string[];
        friendsRequestsIds: string[];
    } = { friendsIds: [], friendsRequestsIds: [] };

    try {
        const response = await fetch(`${BELLINO_BASE_URL}/api/users?action=getUserFriendsIDS`, {
            method: 'GET'
        });

        if (response.ok) {
            const responseData = await response.json();

            if (responseData?.success) {
                userFriendsIDS = responseData.result;
            }
        } else {
            console.error('Network response was not ok');
        }
    } catch (error) {
        console.error('Fetch error:', error);
    }

    return userFriendsIDS;
};

export const searchUserFriends = async (query: string, friendsIDS: string[]) => {
    let searchResults: SearchItemInfo[] = [];

    if (query?.trim()?.length && friendsIDS?.length) {
        try {
            const response = await fetch(
                `${BELLINO_BASE_URL}/ProcessAjaxRequest?action=SearchFriend&search=${query}&data=${friendsIDS.join(',')}`,
                {
                    method: 'GET'
                }
            );

            if (response.ok) {
                const responseData = await response.json();

                if (responseData?.length) {
                    searchResults = responseData.map((friend: any) => {
                        return {
                            id: friend.ID,
                            name: `${friend.FirstName} ${friend.LastName}`
                        } as SearchItemInfo;
                    });
                }
            } else {
                console.error('Network response was not ok');
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    return searchResults;
};

/* User Playlists API */

export const getUserPlaylists = async () => {
    let playlists: PlaylistInfo[] = [];

    try {
        const response = await fetch(`${BELLINO_BASE_URL}/ProcessAjaxRequest?action=GetUserPlaylists`, {
            method: 'GET'
        });

        if (response.ok) {
            const responseData = await response.json();

            if (responseData) {
                playlists = responseData.map((playlistInfo: any) => {
                    const playlistTracksIds: string[] = playlistInfo.TrackList?.split?.(',') ?? [];

                    return {
                        id: playlistInfo.ID,
                        name: playlistInfo.Name,
                        date: playlistInfo.DateModified,
                        numOfTracks: playlistTracksIds.length,
                        tracksIds: playlistTracksIds
                    } as PlaylistInfo;
                });
            }
        } else {
            console.error('Network response was not ok');
        }
    } catch (error) {
        console.error('Fetch error:', error);
    }

    return playlists;
};

export const createPlaylist = async (playlistName: string, playlistTracksIDS: string[] = []) => {
    let playlistID = undefined;

    if (playlistName?.toString()?.length) {
        try {
            let playlistDataQuery = '';

            if (playlistTracksIDS) {
                playlistDataQuery = `&data=${playlistTracksIDS.join(',')}`;
            }

            const response = await fetch(
                `${BELLINO_BASE_URL}/ProcessAjaxRequest?action=CreatePlaylist&name=${playlistName}${playlistDataQuery}`,
                {
                    method: 'GET'
                }
            );

            if (response.ok) {
                const responseData = await response.text();
                playlistID = responseData ? responseData.toString() : undefined;
            } else {
                console.error('Network response was not ok');
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    return playlistID;
};

export const updatePlaylist = async (playlistID: string, playlistName?: string, playlistTracksIDS?: string[]) => {
    let isSuccess = false;

    if (playlistID?.toString()?.length && (playlistName || playlistTracksIDS)) {
        try {
            const formData = new FormData();
            formData.append('action', 'UpdatePlaylist');
            formData.append('plid', playlistID);

            if (playlistName) {
                formData.append('name', playlistName);
            }

            if (playlistTracksIDS) {
                formData.append('data', playlistTracksIDS.join(','));
            }

            const response = await fetch(`${BELLINO_BASE_URL}/ProcessAjaxRequest`, {
                method: 'POST',
                body: formData
            });

            if (response.ok) {
                const responseData = await response.text();
                isSuccess = Boolean(responseData === '1' || responseData === 'true');
            } else {
                console.error('Network response was not ok');
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    return isSuccess;
};

export const deletePlaylist = async (playlistID: string) => {
    let isSuccess = false;

    if (playlistID?.toString()?.length) {
        try {
            const response = await fetch(`${BELLINO_BASE_URL}/ProcessAjaxRequest?action=DeletePlaylist&plid=${playlistID}`, {
                method: 'Get'
            });

            if (response.ok) {
                const responseData = await response.text();
                isSuccess = Boolean(responseData);
            } else {
                console.error('Network response was not ok');
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    return isSuccess;
};

/* User Photos API */

export const addUserPhoto = async (path: string) => {
    let photoData: any = false;

    if (path) {
        try {
            const response = await fetch(`${BELLINO_BASE_URL}/ProcessAjaxRequest?action=AddProfileImage&path=${path}`, {
                method: 'GET'
            });

            if (response.ok) {
                const imageData = await response.json();

                if (imageData?.['ID']) {
                    photoData = imageData;
                    photoData.Path = `${BELLINO_BASE_URL}/${photoData.Path}`;
                }
            } else {
                console.error('Network response was not ok');
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    return photoData;
};

export const deleteUserPhoto = async (photoID: string) => {
    let isSuccess: boolean = false;

    if (typeof photoID !== 'undefined') {
        try {
            const response = await fetch(`${BELLINO_BASE_URL}/ProcessAjaxRequest?action=DeleteUserPhoto&pid=${photoID}`, {
                method: 'GET'
            });

            if (response.ok) {
                const responseData = await response.json();

                if (responseData) {
                    isSuccess = true;
                }
            } else {
                console.error('Network response was not ok');
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    return isSuccess;
};

export const updateUserProfilePhoto = async (photoID: string) => {
    let isSuccess: boolean = false;

    if (typeof photoID !== 'undefined') {
        try {
            const response = await fetch(`${BELLINO_BASE_URL}/ProcessAjaxRequest?action=SetProfilePhoto&pid=${photoID}`, {
                method: 'GET'
            });

            if (response.ok) {
                const responseData = await response.json();

                if (responseData) {
                    isSuccess = true;
                }
            } else {
                console.error('Network response was not ok');
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    return isSuccess;
};

export const resetUserProfilePhoto = async () => {
    return updateUserProfilePhoto('-1');
};

/* Other API */

export const addUserListening = async (trackID: string, albumID?: string, maxNumOfTries: number = 3) => {
    let isSuccess: boolean = false;

    if (trackID.toString().length > 0 && maxNumOfTries > 0) {
        const formData = new FormData();
        formData.append('action', 'AddListening');
        formData.append('tid', trackID);

        if (albumID) {
            formData.append('aid', albumID);
        }

        try {
            const response = await fetch(`${BELLINO_BASE_URL}/ProcessAjaxRequest`, {
                method: 'POST',
                body: formData
            });

            if (response.ok) {
                const responseData = await response.json();

                if (responseData) {
                    isSuccess = true;
                }
            } else {
                console.error('Network response was not ok');
            }
        } catch (error) {
            console.error('Fetch error:', error);

            // In case of failure - try again (till "maxNumOfTries")
            setTimeout(() => {
                addUserListening(trackID, albumID, maxNumOfTries - 1);
            }, 2000);
        }
    }

    return isSuccess;
};

export const getUser = async () => {
    let userInfo: UserInfo | undefined = undefined;

    try {
        const response = await fetch(`${BELLINO_BASE_URL}/api/users?action=getUser`, {
            method: 'GET'
        });

        if (response.ok) {
            const responseData = await response.json();

            if (responseData?.success && responseData.result) {
                userInfo = responseData.result;
            }
        } else {
            console.error('Network response was not ok');
        }
    } catch (error) {
        console.error('Fetch error:', error);
    }

    return userInfo;
};

export const getUserNotifications = async () => {
    let notifications: Message[] = [];

    try {
        const response = await fetch(`${BELLINO_BASE_URL}/api/users?action=getusernotifications`, {
            method: 'GET'
        });

        if (response.ok) {
            const responseData = await response.json();

            if (responseData?.success && responseData.result?.length) {
                notifications = responseData.result.map((notification: any) => {
                    return {
                        id: notification.ID,
                        date: notification.mdate?.toString?.()?.includes?.('UTC') ? notification.mdate : `${notification.mdate} UTC`,
                        message: notification.Content,
                        type: notification.Type,
                        status: 'Sent',
                        senderId: notification.From,
                        senderInfo: {
                            firstName: notification.FirstName,
                            lastName: notification.LastName,
                            imgPath: getImageFullPath(notification.ProfileImage, 'User')
                        }
                    } as Message;
                });
            }
        } else {
            console.error('Network response was not ok');
        }
    } catch (error) {
        console.error('Fetch error:', error);
    }

    return notifications;
};

export const getUsersBaseDetails = async (usersIds: string[]) => {
    let usersBaseDetails: BaseUserInfo[] = [];

    if (usersIds?.length) {
        try {
            const response = await fetch(`${BELLINO_BASE_URL}/api/users`, {
                method: 'POST',
                body: JSON.stringify({
                    action: 'getUsersBaseDetails',
                    usersIds: usersIds.join(',')
                })
            });

            if (response.ok) {
                const responseData = await response.json();

                if (responseData?.success && responseData.result?.length) {
                    usersBaseDetails = responseData.result;
                }
            } else {
                console.error('Network response was not ok');
            }
        } catch (error) {
            console.error('Fetch error:', error);
        }
    }

    return usersBaseDetails;
};
