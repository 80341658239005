import { ArtistInfo } from '../Types/Artist';
import { KeyValue } from '../Types/KeyValue';
import { MusicItemType } from '../Types/MusicItemType';

export const BELLINO_DOMIAN = 'www.belllino.com';
export const BELLINO_BASE_URL = window.location.hostname.includes('belllino.com') ? '' : `https://${BELLINO_DOMIAN}`;

export const DEFAULT_ARTIST_IMG = `${BELLINO_BASE_URL}/Images/default-artist.png`;
export const DEFAULT_RELEASE_IMG = `${BELLINO_BASE_URL}/Images/default-release.png`;
export const DEFAULT_AVATAR_IMG = `${BELLINO_BASE_URL}/Images/default-avatar.png`;

export const getImageFullPath = (imgPath?: string, itemType?: MusicItemType | 'User') => {
    if (imgPath && !imgPath.startsWith('http')) {
        if (imgPath.includes('ImagesDB/') || imgPath.includes('Images/')) {
            return `${BELLINO_BASE_URL}${imgPath.startsWith('/') ? '' : '/'}${imgPath}`;
        }
        return `${BELLINO_BASE_URL}/ImagesDB/${imgPath}`;
    } else if (!imgPath) {
        if (itemType === 'User') {
            return DEFAULT_AVATAR_IMG;
        } else if (itemType === 'Artist') {
            return DEFAULT_ARTIST_IMG;
        } else {
            return DEFAULT_RELEASE_IMG;
        }
    }

    return imgPath;
};

export const getElementFullWidth = (element: HTMLElement, includingMargin: boolean = true) => {
    // Get the element's width, including padding and border
    const width = element.offsetWidth;

    // Get the computed styles of the element
    const style = window.getComputedStyle(element);

    // Get the margin values from the computed styles
    const marginLeft = includingMargin ? parseFloat(style.marginLeft) : 0;
    const marginRight = includingMargin ? parseFloat(style.marginRight) : 0;

    // Return the total width
    return width + marginLeft + marginRight;
};

export const capitalizeFirstLetter = (string: string) => string[0].toUpperCase() + string.slice(1).toLowerCase();

export const capitalizeWords = (input: string): string => {
    return input
        .split(' ')
        .map(word => capitalizeFirstLetter(word))
        .join(' ');
};

export const sumObjectValues = (obj: KeyValue) => (obj ? Object.values(obj).reduce((a, b) => a + b, 0) : 0);

export const htmlToText = (string: string) => string.replace(/<[^>]*>/g, '');

export const getTextFromHtml = (html: string) => {
    const element = document.createElement('div');
    element.innerHTML = html;
    return element.textContent ?? '';
};

export const normalizeText = (text: string) => {
    let normalizedText = text.trim();
    normalizedText = normalizedText.replace(/’/g, "'");
    normalizedText = normalizedText.replace(/`/g, "'");
    return normalizedText;
};

export const handleSearchText = (searchText: string, encodeURI: boolean = true) => {
    let finalSearchText = searchText.trim();

    finalSearchText = finalSearchText.replace(/-/g, ' ');
    finalSearchText = finalSearchText.replace(/–/g, ' ');
    finalSearchText = finalSearchText.replace(/"/g, ' ');
    finalSearchText = finalSearchText.replace(/’/g, "'");
    finalSearchText = finalSearchText.replace(/`/g, "'");
    finalSearchText = finalSearchText.replace(/'/g, "''");
    finalSearchText = finalSearchText.replace(/ +/g, '%'); // Remove extra spaces

    return encodeURI ? encodeURIComponent(finalSearchText) : finalSearchText;
};

export const isClickInsideElement = (e: MouseEvent, elementRect?: DOMRect | null) => {
    if (
        elementRect &&
        e.clientX >= elementRect.left &&
        e.clientX <= elementRect.right &&
        e.clientY >= elementRect.top &&
        e.clientY <= elementRect.bottom
    ) {
        return true;
    }

    return false;
};

export const RandomIntFromInterval = (min: number, max: number) => {
    return Math.floor(Math.random() * (max - min + 1) + min);
};

export const BuildAmazonBuyLink = (itemName: string) => {
    let keywordsString = encodeURIComponent(itemName);
    keywordsString = keywordsString.replace(/%20/g, '+');
    const amazonBuyLink = `https://www.amazon.com/s/ref=nb_sb_noss?url=search-alias%3Dpopular&field-keywords=${keywordsString}`;

    return amazonBuyLink;
};

export const GetFixedForamtDate = (date: string | number | Date) => {
    return typeof date === 'string' ? new Date(date.replace(/-/g, '/')) : new Date(date);
};

export const IsSameDay = (date1: Date, date2: Date) => {
    return date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth() && date1.getFullYear() === date2.getFullYear();
};

export const IsSameDayButYear = (date1: Date, date2: Date) => {
    return date1.getDate() === date2.getDate() && date1.getMonth() === date2.getMonth();
};

export const GetTime = (date: Date) => {
    return date.getHours().toString().padStart(2, '0') + ':' + date.getMinutes().toString().padStart(2, '0');
};

export const GetMonthName = (date: Date, shortFormat: boolean = false) => {
    var months = ['January', 'February', 'March', 'April', 'May', 'June', 'July', 'August', 'September', 'October', 'November', 'December'];
    var monthsS = ['Jan', 'Feb', 'Mar', 'Apr', 'May', 'Jun', 'Jul', 'Aug', 'Sep', 'Oct', 'Nov', 'Dec'];
    return shortFormat ? monthsS[date.getMonth()] : months[date.getMonth()];
};

export const GetDayName = (date: Date) => {
    const days = ['Sunday', 'Monday', 'Tuesday', 'Wednesday', 'Thursday', 'Friday', 'Saturday'];
    return days[date.getDay()];
};

export const FormatDate = (date: string | number | Date, format?: 'Normal' | 'Short' | 'Detailed', separtor = '/') => {
    const dateFormat = format ?? 'Normal';
    const dateObj = GetFixedForamtDate(date);

    if (dateFormat === 'Short') {
        return dateObj.getDate() + ' ' + GetMonthName(dateObj, true) + ' ' + dateObj.getFullYear();
    } else if (dateFormat === 'Detailed') {
        var hourTime = dateObj.getHours().toString().padStart(2, '0') + ':' + dateObj.getMinutes().toString().padStart(2, '0');
        return GetDayName(dateObj) + ', ' + dateObj.getDate() + ' ' + GetMonthName(dateObj) + ' ' + dateObj.getFullYear() + ' at ' + hourTime;
    }

    return (
        dateObj.getDate().toString().padStart(2, '0') +
        separtor +
        (dateObj.getMonth() + 1).toString().padStart(2, '0') +
        separtor +
        dateObj.getFullYear()
    );
};

export const GetNowUTCDate = () => {
    const now = new Date();
    const year = now.getUTCFullYear();
    const month = String(now.getUTCMonth() + 1).padStart(2, '0');
    const day = String(now.getUTCDate()).padStart(2, '0');
    const hours = String(now.getUTCHours()).padStart(2, '0');
    const minutes = String(now.getUTCMinutes()).padStart(2, '0');
    const seconds = String(now.getUTCSeconds()).padStart(2, '0');

    // Combine the values to form the desired string format
    return `${year}-${month}-${day} ${hours}:${minutes}:${seconds} UTC`;
};

export const GetAgeByDate = (date?: string | Date): number | undefined => {
    if (date) {
        const now = new Date();
        const birthDate = GetFixedForamtDate(date);

        // Calculate dates diff
        const yearsDiff = now.getFullYear() - birthDate.getFullYear();
        const monthDiff = now.getMonth() - birthDate.getMonth();
        const daysDiff = now.getDate() - birthDate.getDate();
        const hoursDiff = now.getHours() - birthDate.getHours();
        const minutesDiff = now.getMinutes() - birthDate.getMinutes();
        const secondsDiff = now.getSeconds() - birthDate.getSeconds();

        // Calculate age by dates diff
        let age = yearsDiff;

        if (
            monthDiff < 0 ||
            (monthDiff === 0 && daysDiff < 0) ||
            (monthDiff === 0 && daysDiff === 0 && hoursDiff < 0) ||
            (monthDiff === 0 && daysDiff === 0 && hoursDiff === 0 && minutesDiff < 0) ||
            (monthDiff === 0 && daysDiff === 0 && hoursDiff === 0 && minutesDiff === 0 && secondsDiff < 0)
        ) {
            age--;
        }

        return age;
    }

    return undefined;
};

/************************************************************************************/
/* Shuffle the given array */
export const Shuffle = (arr: any[]) => {
    for (var j, x, i = arr.length; i; j = parseInt((Math.random() * i).toString()), x = arr[--i], arr[i] = arr[j], arr[j] = x);
    return arr;
};

/************************************************************************************/
/* Get Absolute URL. */
export const GetAbsoluteURL = (url: string) => {
    if (url.startsWith('http://') || url.startsWith('https://')) {
        return url;
    }

    return '//' + url;
};

/************************************************************************************/
/* Validate local URL. */
export const IsLocalUrl = (url: string) => {
    if (Validate.URL(url)) {
        return url.indexOf(window.location.hostname.replace('www.', '')) >= 0;
    }

    return true;
};

/************************************************************************************/
/* Validate the given Component */
export const Validate = {
    MIN_PASSWORD_LENGTH: 6,
    MAX_PASSWORD_LENGTH: 128,
    Email: (email: string) => {
        var validEmail = /^[A-Za-z0-9_.-]+@[A-Za-z0-9_.-]+\.[A-Za-z]+(\.[a-z]+)?$/i;
        return validEmail.test(email);
    },
    Password: (password: string) => {
        return password.length < Validate.MIN_PASSWORD_LENGTH ? false : true;
    },
    URL: (url: string) => {
        // eslint-disable-next-line no-useless-escape
        var validURL = /^(http|https|ftp):\/\/([a-z0-9]+([\-\.]{1}[a-z0-9]+)*|localhost)(:[0-9]{1,5})?(\/.*)?$/i;
        return validURL.test(url);
    }
};

/************************************************************************************/
export const disableScrollBar = () => {
    const windowDocument = (window as any)?.document;

    if (windowDocument) {
        const rootHTML = windowDocument.getElementsByTagName('html')[0];

        var documentHeight = Math.max(
            windowDocument.body.scrollHeight,
            windowDocument.body.offsetHeight,
            windowDocument.body.clientHeight,
            windowDocument.body.scrollHeight,
            windowDocument.body.offsetHeight
        );

        var windowHeight = (window as any).innerHeight;

        if (documentHeight > windowHeight) {
            const scrollTop = rootHTML.scrollTop ?? window.document.body.scrollTop;
            rootHTML.classList.add('noscroll');
            rootHTML.style.top = `${-scrollTop}px`;
        }
    }
};

/************************************************************************************/
export const enableScrollBar = () => {
    const windowDocument = (window as any)?.document;

    if (windowDocument) {
        const rootHTML = windowDocument.getElementsByTagName('html')[0];
        const scrollTop = parseFloat(rootHTML.style.top);
        rootHTML.classList.remove('noscroll');
        rootHTML.scrollTo(0, -scrollTop);
    }
};

/************************************************************************************/

export const SecondsToTime = (seconds: number) => {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    const remainingSeconds = seconds % 60;

    if (hours > 0) {
        return `${String(hours).padStart(2, '0')}:${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
    }

    return `${String(minutes).padStart(2, '0')}:${String(remainingSeconds).padStart(2, '0')}`;
};

export const DurationStrToSeconds = (duration: string) => {
    if (!/^\d{1,2}(:\d{2}){1,2}$/.test(duration)) {
        console.error("Invalid duration format. Use 'HH:mm:ss' or 'mm:ss'.", duration);
        throw new Error("Invalid duration format. Use 'HH:mm:ss' or 'mm:ss'.");
    }

    const durationParts = duration.split(':').map(Number).reverse();

    let totalSeconds = 0;

    for (let i = 0; i < durationParts.length; i++) {
        totalSeconds += Math.pow(60, i) * durationParts[i];
    }

    return totalSeconds;
};

/************************************************************************************/

export const ConvertToArtistsArray = (artistID: any, artistName: any) => {
    const artistIDs: string[] = typeof artistID === 'string' ? artistID?.split(';') : Array.isArray(artistID) ? artistID : [];
    const artistNames: string[] = typeof artistName === 'string' ? artistName?.split(';') : Array.isArray(artistName) ? artistName : [];

    return artistIDs.map((id, index) => {
        return {
            id,
            name: artistNames[index] ?? ''
        };
    });
};

/************************************************************************************/

export const BuildTrackFullName = (trackName: string, trackArtists?: ArtistInfo[]) => {
    if (trackArtists?.length) {
        return `${trackArtists.map(a => a.name).join(', ')} - ${trackName}`;
    }

    return trackName;
};

/************************************************************************************/

const SendRequest = async <T = any>(
    url: string,
    responseDataType: 'json' | 'text' = 'json',
    method: 'GET' | 'POST' = 'GET',
    payload?: object | FormData
): Promise<T | { success: false; result: undefined; message: string }> => {
    const isLocalUrl = IsLocalUrl(url);
    const isFormData = payload instanceof FormData;

    try {
        const response = await fetch(url, {
            method,
            headers:
                isFormData || method === 'GET'
                    ? {} // No headers for FormData or GET
                    : {
                          //Accept: responseDataType === 'json' ? 'application/json' : 'text/plain',
                          //'Content-Type': 'application/json' // Only set Content-Type for JSON requests
                      },
            body:
                method === 'POST'
                    ? isFormData
                        ? payload // For FormData, don't stringify, just pass the FormData object
                        : JSON.stringify(payload) // For JSON, stringify the payload
                    : undefined
        });

        if (!response.ok) {
            const errorMessage = `Network response was not ok (${response.status})`;
            console.error(errorMessage);

            // If the request is to Belllino, return structured error response
            if (isLocalUrl) {
                return { success: false, result: undefined, message: errorMessage };
            }

            throw new Error(`Network response was not ok (${response.status})`);
        }

        const data = responseDataType === 'json' ? await response.json() : await response.text();

        // Ensures the response is typed correctly
        return data as T;
    } catch (error) {
        console.error('Fetch error:', error);

        // If the request is to Belllino, return structured error response
        if (isLocalUrl) {
            return { success: false, result: undefined, message: (error as Error).message };
        }

        throw error; // For external requests, let the error propagate
    }
};

export const FetchData = (url: string, responseDataType: 'json' | 'text' = 'json') => {
    return SendRequest(url, responseDataType, 'GET');
};

export const PostData = (url: string, payload: object, responseDataType: 'json' | 'text' = 'json') => {
    return SendRequest(url, responseDataType, 'POST', payload);
};

export const FetchPageData = (url: string) => {
    return new Promise(async resolve => {
        const pageData = (window as any).page_data;

        if (pageData && !pageData.used) {
            pageData.used = true; // mark as used so the next pages won't use it (till next user page reload)
            resolve(pageData);
        } else {
            resolve(await FetchData(url));
        }
    });
};

/************************************************************************************/

export const CalculateAverage = (numbers: number[]): number | null => {
    if (numbers.length === 0) return null;

    const sum = numbers.reduce((total, num) => total + num, 0);
    return sum / numbers.length;
};

/************************************************************************************/

export const CalculateMedian = (numbers: number[]): number | null => {
    return calculatePercentile(numbers, 50);
};

export const calculatePercentile = (numbers: number[], percent: number = 50): number | null => {
    if (numbers.length === 0) return null;

    // Validate and normalize the percent value
    const normalizedPercent = Math.min(Math.max(percent, 0), 100) / 100;

    // Sort the array in ascending order
    const sortedNumbers = [...numbers].sort((a, b) => a - b);

    // Calculate the index based on the percentage
    const percentageIndex = Math.floor(sortedNumbers.length * normalizedPercent);

    // In case of true median (percentage is 50):
    // 1. when the array has an odd length, return the middle element
    // 2. when the array has an even length, return the average of the two middle elements
    if (percent === 50) {
        return sortedNumbers.length % 2 !== 0
            ? sortedNumbers[percentageIndex]
            : (sortedNumbers[percentageIndex - 1] + sortedNumbers[percentageIndex]) / 2;
    }

    // For other percentages, return the element closest to the calculated percentage index
    return sortedNumbers[percentageIndex];
};
